<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :class="{ row, error: v$.user.givenName.$errors.length }"
            :label="$t('forms.givenName')"
            label-for="h-first-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="user.givenName" id="h-first-name"/>
            <div
              class="input-errors"
              v-for="error of v$.user.givenName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.user.familyName.$errors.length }"
            :label="$t('forms.familyName')"
            label-for="h-last-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="user.familyName" id="h-last-name"/>
            <div
              class="input-errors"
              v-for="error of v$.user.familyName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('forms.telephone')"
            label-for="h-phone"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-phone" v-model="user.telephone"/>

          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.user.email.$errors.length }"
            :label="$t('forms.email')"
            label-for="h-email"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-email" type="email" v-model="user.email"/>
            <div
              class="input-errors"
              v-for="error of v$.user.email.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.user.plainPassword.$errors.length }"
            :label="$t('forms.password')"
            label-for="password"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="password"
              type="password"
              v-model="user.plainPassword"
            />
            <BootstrapIcon
              icon="eye"
              size="1x"
              class="showIcon"
              @click="togglePassword"
            />
            <div
              class="input-errors"
              v-for="error of v$.user.plainPassword.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('forms.rolePrivileges')"
            label-for="h-rolePrivileges"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <v-select
              id="h-rolePrivileges"
              v-model="rolePrivilegesSelected"
              :options="rolePrivileges"
              multiple
            />
          </b-form-group>

        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {BRow, BCol, BFormGroup, BFormInput, BForm} from "bootstrap-vue-3";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect
  },
  props: {
    userObject: {Object, required: true},
    action: {type: String, default: null},
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      user: {
        givenName: {required},
        familyName: {required},
        email: {required, email},
        plainPassword: {
          minLengthValue: minLength(8),
        },
      },
    };
  },
  created() {
    this.getEnumList(["role_privilege"], this.rolePrivileges);
    if (this.user.rolePrivileges && this.user.rolePrivileges.length) {
      this.user.rolePrivileges.forEach((selectedRolePrivilege) => {
        this.rolePrivilegesSelected.push({
          id: selectedRolePrivilege,
          label: this.$helper.getEnumTranslation('role_privilege', selectedRolePrivilege)
        })
      });
    }
    console.log(this.rolePrivilegesSelected)
  },
  data() {
    return {
      user: this.userObject,
      rolePrivileges: [],
      rolePrivilegesSelected: []
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    togglePassword() {
      const password = document.querySelector("#password");
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.user.plainPassword === "") {
          delete this.user.plainPassword;
        }
        this.user.address.flatNumber = this.user.address.flatNumber
          ? parseInt(this.user.address.flatNumber, 10)
          : null;
        this.user.address.floorNumber = this.user.address.floorNumber
          ? parseInt(this.user.address.floorNumber, 10)
          : null;

        if (
          this.rolePrivilegesSelected &&
          this.rolePrivilegesSelected.length
        ) {
          this.user.rolePrivileges = []
          this.rolePrivilegesSelected.forEach((rolePrivilege) => {
            this.user.rolePrivileges.push(rolePrivilege.id)
          });
        }

        if (this.action) {
          this.action === "add" ?
            this.create(this.$Users, this.user, this.$t("messages.employeeCreated"), null, this.success, this.error) :
            this.update(this.$Users, this.user.id, this.user, this.$t("messages.employeeUpdated"), null, null, this.error);
          this.$emit("clearAction");
        }

      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/employees`);
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
